<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="px-2">
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Course-list'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link>  {{ courseData.course_name }}
        <StatusBlock
          class="ml-2"
          :status="String(courseData.course_status_id)"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-2 d-none d-md-block"
          @click="isAddDrugToCourse = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_drug') }}
        </v-btn>
        <v-btn
          color="primary"
          class="me-2 d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddDrugToCourse = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-btn
          color="info"
          class="d-none d-md-block"
          @click="isAddEquipmentToCourse = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_equipment') }}
        </v-btn>
        <v-btn
          color="info"
          class="d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddEquipmentToCourse = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-5">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p> <span class="font-weight-semibold"> {{ $t('code') }}  : </span>{{ courseData.course_id }}</p>
          <p
            v-if="
              courseData.course_type == 1 || courseData.course_type == 2
            "
          >
            <span class="font-weight-semibold"> {{ $t('amount') }}  : </span>{{
              courseData.course_type == 1
                ? `${courseData.course_amount} ${$t("times_courses")}`
                : ""
            }}
            {{
              courseData.course_type == 2
                ? `${courseData.course_expday} ${$t("day")}`
                : ""
            }}
          </p>

          <p>
            <span class="font-weight-semibold"> {{ $t('staff_costs') }}  : </span>{{ courseData.course_costuser }}
          </p>
          <p v-if=" courseData.course_type == 3">
            <span class="font-weight-semibold"> {{ $t('other_details') }}  : </span>{{ courseData.detail }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p> <span class="font-weight-semibold"> {{ $t('course_name') }}  : </span>{{ courseData.course_name }}</p>
          <p> <span class="font-weight-semibold"> {{ $t('category') }}  : </span>{{ courseData.coursegroup_name }}</p>
          <p v-if=" courseData.course_type != 3">
            <span class="font-weight-semibold"> {{ $t('other_details') }}  : </span>{{ courseData.course_detail }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <p>
            <span class="font-weight-semibold"> {{ $t('type') }}  : </span><CourseType
              class="ml-2"
              :type="courseData.course_type"
            />
          </p>
          <p> <span class="font-weight-semibold"> {{ $t('doctor_fee') }}  : </span>{{ courseData.course_costdoctor }}</p>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width:10px">
                #
              </th>
              <th
                class="text-center"
                width="120px"
              >
                {{ $t("group") }}
              </th>
              <th width="300px">
                {{ $t("list") }}
              </th>
              <th
                width="120px"
                class="text-right"
              >
                {{ $t("amount") }}
              </th>
              <th
                class="text-right"
                width="120px"
              >
                {{ $t("cost_unit") }}
              </th>
              <th
                class="text-right"
                width="120px"
              >
                {{ $t("total_price") }}
              </th>

              <th
                class="text-center"
                width="120px"
              >
                {{ $t("status") }}
              </th>
              <th
                class="text-center"
                width="160px"
              >
                {{ $t("option") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!data_list.length">
              <td
                colspan="8"
                class="text-center"
              >
                {{ $t('no_information') }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in data_list"
              :key="index"
            >
              <td style="width:10px">
                {{ index + 1 }}
              </td>
              <td class="text-center">
                <DrugStatus :type="item.drug_category_id" />
              </td>
              <td>{{ item.drug_name }} ({{ item.drug_id }})</td>
              <td class="text-right">
                <span v-if="item.course_total=='0'">
                  <v-chip
                    v-if="item.drug_total == null || item.drug_total == '0.00'"
                    small
                    color="error"
                    class="v-chip-light-bg error--text"
                  >
                    <v-icon
                      small
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                    {{ $t
                      ("empty") }}
                  </v-chip>
                </span>
                <span v-else>
                  {{ item.coursedrug_amount | formatPrice }} ({{ item.drug_unit }})
                </span>
              </td>
              <td class="text-right">
                {{ showCost ? item.drugorder_cost : "xxx" }}
              </td>
              <td class="text-right">
                {{ showCost ? item.course_total : "xxx" }}
              </td>
              <td class="text-center">
                <StatusBlock :status="String(item.coursedrug_status_id)" />
              </td>
              <td class="text-center">
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      @click="dataEdit = item;isUserConfirm= true"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("edit") }}</span>
                </v-tooltip>
                <v-tooltip

                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      @click="coursedrug_status_id = item.coursedrug_status_id;coursedrug_id = item.coursedrug_id;isUpdateStatus= true"
                      v-on="on"
                    >
                      <v-icon>{{ item.coursedrug_status_id == 1? icons.mdiClose:icons.mdiCheck }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.coursedrug_status_id == 1? $t("suspend") :$t('normal') }}</span>
                </v-tooltip>
                <v-tooltip
                  color="#212121"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      @click="coursedrug_id= item.coursedrug_id; isDelete= true"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("delete") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <AddDrugToCourse
      v-model="isAddDrugToCourse"
      :course-id-pri="$router.currentRoute.params.id"
      @onAdd="getCourseDrugList"
    />
    <AddEquipmentToCourse
      v-model="isAddEquipmentToCourse"
      :course-id-pri="$router.currentRoute.params.id"
      @onAdd="getCourseDrugList"
    />
    <EditDrugInCourse
      v-model="isEditDrugInCourse"
      :data-edit="dataEdit"
      @onUpdate="getCourseDrugList"
    />
    <UserConfirm
      v-model="isUserConfirm"
      @onSend="isEditDrugInCourse = true"
    />
    <UpdateStatusDrugInCourse
      v-model="isUpdateStatus"
      :coursedrug-status-id="String(coursedrug_status_id)"
      :coursedrug-id="coursedrug_id"
      @onUpdate="getCourseDrugList"
    />
    <DeleteDrugInCourse
      v-model="isDelete"
      :coursedrug-id="coursedrug_id"
      @onDelete="getCourseDrugList"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiArrowLeftBold, mdiPlus, mdiCheck, mdiDeleteOutline, mdiClose, mdiPencilOutline,
} from '@mdi/js'
import router from '@/router'
import courseList from '@/api/courseManagement/courseList'
import CourseType from '@/components/basicComponents/CourseType.vue'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import profile from '@/api/profile'
import { formatPrice } from '@/plugins/filters'
import AddDrugToCourse from './AddDrugToCourse.vue'
import AddEquipmentToCourse from './AddEquipmentToCourse.vue'
import EditDrugInCourse from './EditDrugInCourse.vue'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'
import UpdateStatusDrugInCourse from './UpdateStatusDrugInCourse.vue'
import DeleteDrugInCourse from './DeleteDrugInCourse.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    DrugStatus,
    CourseType,
    StatusBlock,
    AddDrugToCourse,
    AddEquipmentToCourse,
    EditDrugInCourse,
    UserConfirm,
    UpdateStatusDrugInCourse,
    DeleteDrugInCourse,
  },
  setup() {
    const { courseGet, courseDrugList } = courseList
    const courseData = ref({})
    const loading = ref(true)
    const data_list = ref([])
    const showCost = ref(false)
    const isUserConfirm = ref(false)

    const isAddDrugToCourse = ref(false)
    const isAddEquipmentToCourse = ref(false)

    const isUpdateStatus = ref(false)
    const coursedrug_status_id = ref('')
    const coursedrug_id = ref('')
    const isEditDrugInCourse = ref(false)
    const dataEdit = ref({})

    const isDelete = ref(false)

    profile().then(res => {
      showCost.value = res.user_data_customers == '0'
    })

    courseGet(router.currentRoute.params.id).then(res => {
      courseData.value = res
      getCourseDrugList()
    })
    const getCourseDrugList = () => {
      loading.value = true
      courseDrugList({ course_id_pri: router.currentRoute.params.id }).then(res => {
        data_list.value = res
        loading.value = false
      })
    }

    return {
      isDelete,
      isUserConfirm,
      coursedrug_status_id,
      coursedrug_id,
      isEditDrugInCourse,
      dataEdit,
      getCourseDrugList,
      isAddEquipmentToCourse,
      isUpdateStatus,
      isAddDrugToCourse,
      showCost,
      data_list,
      courseData,
      loading,
      icons: {
        mdiArrowLeftBold,
        mdiPlus,
        mdiCheck,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
