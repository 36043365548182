import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const courseListGet = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'course/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get course  list : ', err)

      return {}
    })

  return response
}
const courseGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'course',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('get course   : ', err)

      return {}
    })

  return response
}

const courseAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'course',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add course  : ', err)

      return {}
    })

  return response
}

const courseUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'course',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update course  : ', err)

      return {}
    })

  return response
}

const courseStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'course/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update status course  : ', err)

      return {}
    })

  return response
}

const courseDrugList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'course/druglist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get course drug list  : ', err)

      return []
    })

  return response
}

const courseDrugAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'course/drug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add course drug  : ', err)

      return {}
    })

  return response
}

const courseDrugUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'course/drug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update course drug  : ', err)

      return {}
    })

  return response
}

const courseDrugUpdateStatus = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'course/drugstatus',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update status course drug  : ', err)

      return {}
    })

  return response
}
const courseDrugRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'course/drug',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('delete course drug  : ', err)

      return {}
    })

  return response
}

const courseImportFile = async (path, body) => {
  ////await refreshToken()
  const response = await api
    .post({
      path,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('import file course : ', err)

      return {}
    })

  return response
}
export default {
  courseListGet,
  courseAdd,
  courseUpdate,
  courseStatusUpdate,
  courseGet,
  courseDrugList,
  courseDrugAdd,
  courseDrugUpdate,
  courseDrugUpdateStatus,
  courseDrugRemove,
  courseImportFile,
}
