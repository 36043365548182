<template>
  <div>
    <v-dialog
      v-model="isAddDrugToCoruse"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_drug') }}
        </v-card-title>
        <v-form
          ref="formAddDrugToCourse"
          @submit.prevent="addDrugToCourse"
        >
          <v-card-text>
            <v-autocomplete
              v-model="drug_id_pri"
              :items="drug_list"
              :search-input.sync="search"
              :filter="customFilter"
              auto-select-first
              :rules="[required]"
              dense
              item-text="drug_name"
              :no-data-text="$t('no_information')"
              item-value="drug_id_pri"
              :label="$t('serach_drug_list')"
              outlined
            >
              <template v-slot:selection="{ item }">
                ({{ item.drug_id }}) {{ item.drug_name }}  <v-chip
                  v-if="item.drug_total == null || item.drug_total == '0.00'"
                  small
                  color="error"
                  class="v-chip-light-bg error--text"
                >
                  {{ $t("empty") }}
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.drug_name }}
                    <v-chip
                      v-if="item.drug_total == null || item.drug_total == '0.00'"
                      small
                      color="error"
                      class="v-chip-light-bg error--text"
                    >
                      {{ $t("empty") }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.drug_id }}
                </v-list-item-action>
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="coursedrug_amount"
              type="number"
              :rules="[required]"
              class="mt-3"
              outlined
              :label="$t('number_used')"
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading "
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-drug-to-coruse',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import drugStore from '@/api/DrugManagement/drugStore'
import { required } from '@/@core/utils/validation'
import courseList from '@/api/courseManagement/courseList'
import store from '@/store'

export default {
  model: {
    prop: 'isAddDrugToCoruse',
    event: 'update:is-add-drug-to-coruse',
  },
  props: {
    isAddDrugToCoruse: {
      type: Boolean,
      default: false,
    },
    courseIdPri: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const drug_id_pri = ref('')
    const drug_list = ref([])
    const search = ref('')
    const coursedrug_amount = ref('1')
    const formAddDrugToCourse = ref('')
    const loading = ref(false)

    const { drugList } = drugStore

    const { courseDrugAdd } = courseList

    drugList({
      searchtext: '',
      drug_category_id: 1,
      druggroup_id: '',
      drugtype_id: '',
      drug_status_id: 1,
      active_page: 1,
      per_page: 0,
    }).then(res => {
      drug_list.value = res.data
    })

    const addDrugToCourse = () => {
      const isFormValid = formAddDrugToCourse.value.validate()
      if (!isFormValid) return
      loading.value = true
      courseDrugAdd({
        course_id_pri: props.courseIdPri,
        drug_id_pri: drug_id_pri.value,
        drug_category_id: 1,
        coursedrug_amount: coursedrug_amount.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        formAddDrugToCourse.value.reset()

        emit('update:is-add-drug-to-coruse', false)
        emit('onAdd')
      })
    }
    const customFilter = (item, queryText) => {
      if (item.drug_id) {
        const textOne = item.drug_id.toLowerCase()
        const textTwo = item.drug_name.toLowerCase()
        const searchText = queryText.toLowerCase()

        return (
          textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        )
      }

      return null
    }

    return {
      loading,
      formAddDrugToCourse,
      coursedrug_amount,
      search,
      customFilter,
      drug_id_pri,
      required,
      drug_list,
      addDrugToCourse,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
