<template>
  <div>
    <v-dialog
      v-model="isDelete"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="error"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>  {{ $t('confirm_deletion') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loading"
            :disabled="loading"
            @click="confirmDelete"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-delete',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiAlertOutline } from '@mdi/js'
import courseList from '@/api/courseManagement/courseList'
import store from '@/store'

export default {
  model: {
    prop: 'isDelete',
    event: 'update:is-delete',
  },
  props: {
    isDelete: {
      type: Boolean,
      default: false,
    },
    coursedrugId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const { courseDrugRemove } = courseList

    const confirmDelete = () => {
      loading.value = true
      courseDrugRemove(props.coursedrugId).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-delete', false)
        emit('onDelete')
      })
    }

    return {
      loading,
      confirmDelete,
      icons: {
        mdiAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
