<template>
  <div>
    <v-dialog
      v-model="isEditDrugInCourse"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit') }}{{ dataEditLocal.drug_category_id==1?$t('drug'):$t('equipment') }}
        </v-card-title>
        <v-form
          ref="formEdit"
          @submit.prevent="updateDrug"
        >
          <v-card-text>
            <v-text-field
              v-model="dataEditLocal.drug_name"
              outlined
              :label="dataEditLocal.drug_category_id==1?$t('drug_name'):$t('equipment_name')"
              dense
              disabled
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.coursedrug_amount"
              :rules="[required]"
              outlined
              :label="$t('number_used')"
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading || dataEditLocal.coursedrug_amount<0"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-drug-in-course', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import courseList from '@/api/courseManagement/courseList'
import store from '@/store'

export default {
  model: {
    prop: 'isEditDrugInCourse',
    event: 'update:is-edit-drug-in-course',
  },
  props: {
    isEditDrugInCourse: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const formEdit = ref(null)

    const loading = ref(false)

    const { courseDrugUpdate } = courseList

    const updateDrug = () => {
      const isFormValid = formEdit.value.validate()
      if (!isFormValid) return
      loading.value = true
      delete dataEditLocal.value.course_total
      delete dataEditLocal.value.coursedrug_status_id
      delete dataEditLocal.value.drug_category_id
      delete dataEditLocal.value.drug_id
      delete dataEditLocal.value.drug_id_pri
      delete dataEditLocal.value.drug_name
      delete dataEditLocal.value.drug_unit
      delete dataEditLocal.value.drugorder_cost
      courseDrugUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-drug-in-course', false)
        emit('onUpdate')
        loading.value = false
      })
    }

    watch(() => props.dataEdit, val => {
      if (val) {
        dataEditLocal.value = JSON.parse(JSON.stringify(val))
      }
    })

    return {
      loading,
      dataEditLocal,
      formEdit,
      updateDrug,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
